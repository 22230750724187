<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card p-1">
      <div class="d-flex mb-1">
        <b-button
          v-for="(tab, index) in tabs"
          :key="index"
          variant="outline-primary"
          class="mr-1 pd-1"
          :class="{ active: currentTab === index }"
          @click="currentTab = index"
        ><span class="fn">{{ tab }}</span>
        </b-button>
      </div>

      <div v-show="currentTab === 0">
        <div class="card_credit">
          <p><i class="fas fa-comments-alt-dollar" /> ยอดคงเหลือสำหรับส่งข้อความ</p>

          <h3>
            {{ credit.credit ? credit.credit: 'กรูณาตรวจสอบ API-KEY' }} เครดิต
          </h3>
        </div>

        <hr>

        <b-row>
          <b-col
            md="6"
            cols="12"
          >
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="dateStart">วันที่เริ่มต้น</label>
                  <flat-pickr
                    v-model="dateStart"
                    class="form-control"
                    placeholder="เลือกเวลาเริ่มต้น"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="dateEnd">วันที่สิ้นสุด</label>
                  <flat-pickr
                    v-model="dateEnd"
                    class="form-control"
                    placeholder="เลือกเวลาสิ้นสุด"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="username">ค้าหาด้วยเบอร์</label>
                  <input
                    id="username"
                    v-model="search"
                    type="text"
                    class="form-control"
                    placeholder="กรอกเบอร์ที่ต้องการค้นหา"
                  >
                </div>
              </div>
            </div>

          </b-col>

          <b-col
            md="6"
            cols="12"
          >
            <b-button
              variant="gradient-warning"
              class="mt-md-2 mr-1"
              @click="month = false , Getlist()"
            >
              ค้นหา
            </b-button>
            <b-button
              variant="gradient-info"
              class="mt-md-2 mr-1"
              @click="month = true , Getlist()"
            >
              ค้นหาลูกค้าที่ออนไลน์มากว่า 1 เดือน
            </b-button>
            <b-button
              variant="gradient-danger"
              class="mt-md-2 mr-1"
              @click="showModalAll()"
            >
              ส่งข้อความทั้งหมด
            </b-button>
          <!-- <b-button
            variant="gradient-info"
            class="mt-md-2 mt-1 mr-1"
          >
            Export
          </b-button> -->
          </b-col>
        </b-row>

        <div class="mt-1">
          <div class="css_txt_head">
            <h3>จำนวนทั้งหมด {{ totalRows }} รายการ จากวันที่ {{ resdata.startcheck }} ถึง {{ resdata.endcheck }}</h3>

          <!-- <h3>จากวันที่ 00/00/2023 ถึง 00/00/2023</h3> -->
          </div>

          <div>
            <b-table
              small
              striped
              hover
              responsive
              class="position-relative items-center"
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
            >
              <template #cell(index)="data">
                {{ perPage * (currentPage - 1) + (data.index + 1) }}
              </template>
              <template #cell(picture)="data">
                <b-avatar
                  size="lg"
                  :src="data.item.picture"
                />
              </template>
              <template #cell(fullname)="data">
                {{ data.item.name }} {{ data.item.surname }}
              </template>
              <!-- Column: Actions -->
              <!-- #cell(send)="data" -->
              <template #cell(send)="data">
                <b-button
                  variant="gradient-success"
                  @click="showModal(data.item)"
                >
                  ส่ง
                </b-button>
              </template>
            </b-table>

            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- page length -->
              <b-form-group
                label="Per Page"
                label-cols="8"
                label-align="left"
                label-size="sm"
                label-for="sortBySelect"
                class="text-nowrap mb-md-0 mr-1"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                />
              </b-form-group>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </div>
        </div>

        <!-- modal -->
        <b-modal
          ref="my-modal"
          title="ข้อความ"
          size="lg"
          ok-title="ยืนยัน"
          cancel-title="ยกเลิก"
          @ok="submitOne()"
        >
          <div>
            <b-form-group>
              <label for="typeSelect"><span class="text-danger">**</span>กรุณาเลือกชื่อผู้ส่ง<span class="text-danger">**</span></label>
              <b-form-select
                id="name"
                v-model="sender"
                :options="sender_list"
              />
            </b-form-group>

            <b-form-group>
              <label for="textarea-default"><span class="text-danger">**</span>ไม่เกิน 70 ตัวอักษร<span class="text-danger">**</span></label>
              <b-form-textarea
                id="textarea-default"
                v-model="message"
                placeholder="กรุณากรอกข้อความที่ต้องการส่ง"
                rows="3"
              />
              <small
                class="text-right float-right"
                :class="{'text-danger': hasError }"
              >{{ remainingCount }}/70</small>
            </b-form-group>

            <div>
              <h6 class="text-danger m-0">
                ** ข้อจำกัดในการส่งข้อความ **
              </h6>
              <small class="text-danger">- กรณีที่ลูกค้าของท่านไม่ได้รับข้อความ เกิดจากการบล็อกข้อความที่เป็นสแปม ซึ่งกรณีนี้ทางเราไม่สามารถตรวจสอบได้ ขออภัยในความไม่สะดวก</small><br>
              <small class="text-danger">- ห้ามใช้อักษรพิเศษ</small>
            </div>
          </div>
        </b-modal>
        <!-- ส่งทั้งหมด -->
        <b-modal
          ref="my-modal-all"
          title="ข้อความ"
          size="lg"
          ok-title="ยืนยัน"
          cancel-title="ยกเลิก"
          @ok="submitAll()"
        >
          <div>
            <b-form-group>
              <label for="typeSelect"><span class="text-danger">**</span>กรุณาเลือกชื่อผู้ส่ง<span class="text-danger">**</span></label>
              <b-form-select
                id="name"
                v-model="sender"
                :options="sender_list"
              />
            </b-form-group>

            <b-form-group>
              <label for="textarea-default"><span class="text-danger">**</span>ไม่เกิน 70 ตัวอักษร<span class="text-danger">**</span></label>
              <b-form-textarea
                id="textarea-default"
                v-model="message"
                placeholder="กรุณากรอกข้อความที่ต้องการส่ง"
                rows="3"
              />
              <small
                class="text-right float-right"
                :class="{'text-danger': hasError }"
              >{{ remainingCount }}/70</small>
            </b-form-group>

            <div>
              <h6 class="text-danger m-0">
                ** ข้อจำกัดในการส่งข้อความ **
              </h6>
              <small class="text-danger">- กรณีที่ลูกค้าของท่านไม่ได้รับข้อความ เกิดจากการบล็อกข้อความที่เป็นสแปม ซึ่งกรณีนี้ทางเราไม่สามารถตรวจสอบได้ ขออภัยในความไม่สะดวก</small><br>
              <small class="text-danger">- ห้ามใช้อักษรพิเศษ</small>
            </div>
          </div>
        </b-modal>
      </div>

      <div v-show="currentTab === 1">
        <div>
          <b-table
            striped
            hover
            responsive
            class="position-relative items-center"
            :per-page="perPageHistory"
            :current-page="currentPageHistory"
            :items="history"
            :fields="fields_history"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
          >
            <template #cell(index)="data">
              {{ perPageHistory * (currentPageHistory - 1) + (data.index + 1) }}
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="8"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPageHistory"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPageHistory"
                :total-rows="totalRowsHistory"
                :per-page="perPageHistory"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </div>
      </div>

      <div v-show="currentTab === 2">
        <hr>
        <div>
          <b-button
            variant="gradient-info"
            @click="nameSend()"
          >
            เพิ่มชื่อผู้ส่ง
          </b-button>
        </div>
        <div>
          <b-table
            striped
            hover
            responsive
            class="position-relative items-center"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields_send"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
          >
            <template #cell(index)="data">
              {{ perPage * (currentPage - 1) + (data.index + 1) }}
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="8"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </div>

        <b-modal
          ref="my-modal-name"
          title="เพิ่มชื่อผู้ส่ง"
          ok-title="ยืนยัน"
          cancel-title="ยกเลิก"
        >
          <div>
            <b-form-group>
              <label for="name">ชื่อผู้ส่ง<span class="text-danger">**</span></label>
              <b-form-input
                id="name"
                v-model="name"
                placeholder="กรุณากรอกชื่อผู้ส่ง"
              />
            </b-form-group>
          </div>
        </b-modal>
      </div>

    </div>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line semi, object-curly-newline
import { BAvatar, BButton, BCol, BFormGroup, BFormInput, BFormSelect, BFormTextarea, BModal, BPagination, BRow, BTable, BOverlay, BIconController } from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment-timezone'

export default ({
  name: 'Sms',
  components: {
    flatPickr,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    BTable,
    BAvatar,
    BPagination,
    BModal,
    BFormTextarea,
    BFormInput,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      name: '',
      maxCount: 70,
      remainingCount: 70,
      message: '',
      hasError: false,
      perPage: 10,
      currentPage: 1,
      currentPageHistory: 1,
      perPageHistory: 10,
      totalRows: 1,
      sortBy: null,
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [10, 25, 50, 100],
      sender_list: [
        { value: 'Overhead', text: 'Overhead' },
        { value: 'Income', text: 'Income' },
        // { value: 'Privileged', text: 'Privileged' },
        { value: 'Privacy', text: 'Privacy' },
      ],
      items: [],
      fields: [
        { key: 'index', label: 'ลำดับ', sortable: false },
        { key: 'username', label: 'Username', sortable: false },
        { key: 'fullname', label: 'ชื่อ-นามสกุล', sortable: false },
        { key: 'tel', label: 'เบอร์โทร', sortable: false },
        {
          key: 'lastlogin', label: 'ล็อคอินล่าสุด', sortable: false, formatter: value => (value ? moment(value).tz('Asia/Seoul').add(-7, 'hours').format('DD/MM/YYYY HH:mm:ss') : ''),
        },
        { key: 'deplast_amount', label: 'จำนวนที่เติมล่าสุด', sortable: false },
        { key: 'deplast_date', label: 'วันที่เติมล่าสุด', sortable: false },
        { key: 'thssms_lastsend', label: 'วันที่ส่งข้อความล่าสุด', sortable: false },
        { key: 'send', label: 'ส่งข้อความ', sortable: false },
      ],
      items_send: [{ index: 1 }],
      fields_send: [
        { key: 'index', label: 'ลำดับ', sortable: false },
        { key: 'name', label: 'ชื่อผู้ส่ง', sortable: false },
      ],
      items_history: [{ index: 1 }],
      fields_history: [
        { key: 'index', label: 'ลำดับ', sortable: false },
        { key: 'username', label: 'Username', sortable: false },
        { key: 'message', label: 'ข้อความ', sortable: false },
        { key: 'count', label: 'จำนวนที่ส่ง', sortable: false },
        { key: 'active_after', label: 'ฝากเข้ามาหลังจากส่ง', sortable: false },
        { key: 'credit_usage', label: 'เครดิตที่ใช้', sortable: false },
        { key: 'credit_before', label: 'เครดิตก่อนส่ง', sortable: false },
        { key: 'credit', label: 'เครดิตหลังส่ง', sortable: false },
        { key: 'created_at', label: 'เวลา', formatter: value => (value ? moment(value).tz('Asia/Seoul').format('DD/MM/YYYY HH:mm:ss') : '') },
      ],
      type: null,
      typeOptions: [
        { value: null, text: 'กรุณาเลือกประเภทที่ต้องการค้นหา' },
        { value: 'A', text: 'Option A' },
        { value: 'B', text: 'Option B' },
        { value: 'C', text: 'Option C' },
      ],
      tabs: ['ส่งข้อความ', 'ประวัติการส่ง'],
      currentTab: 0,
      show: false,
      resdata: [],
      credit: 0,
      senddataone: null,
      sender: null,
      history: [],
      totalRowsHistory: 1,
      dateStart: moment().tz('Asia/Seoul').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Seoul').format('YYYY-MM-DD'),
      ArrayAll: [],
      month: false,
      search: null,
    }
  },
  mounted() {
    this.Getcredit()
    this.Getlist()
    this.GetHistory()
  },
  methods: {
    Getcredit() {
      this.$http
        .get('/thssms/GetCredit')
        .then(response => {
          this.credit = response.data
        })
        .catch(error => console.log(error))
    },
    GetHistory() {
      const obj = {
        page: this.currentPage,
        perPage: this.perPageHistory,
      }
      this.$http
        .post('/thssms/GetHistory', obj)
        .then(response => {
          this.history = response.data.data
          this.totalRowsHistory = response.data.total
        })
        .catch(error => console.log(error))
    },
    Getlist() {
      this.show = true
      const obj = {
        start: this.dateStart,
        end: this.dateEnd,
        month: this.month,
        search: this.search,
      }
      // console.log(obj)
      this.$http
        .post('/thssms/index', obj)
        .then(response => {
          this.resdata = response.data
          this.onFiltered(response.data)
          this.show = false
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.items = filteredItems.data
      this.totalRows = filteredItems.data.length
      // this.currentPage = 1
    },
    submitOne() {
      if (this.sender && this.message) {
        const formData = {
          sender: this.sender,
          message: this.message,
          tel: [this.senddataone.tel],
        }
        this.$http
          .post('/thssms/Sendsms', formData)
          .then(() => {
            this.show = false
            this.sender = null
            this.message = null
            this.senddataone = null
            this.Getcredit()
            this.Success('ส่งข้อความ สำเร็จ')
          })
          .catch(error => {
            this.show = false
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$refs['my-modal'].show()
        this.SwalError('กรุณากรอกข้อมูลให้ครบทุกช่อง')
      }
    },
    submitAll() {
      if (this.sender && this.message) {
        const formData = {
          sender: this.sender,
          message: this.message,
          tel: this.ArrayAll,
        }
        this.$http
          .post('/thssms/Sendsms', formData)
          .then(() => {
            this.show = false
            this.sender = null
            this.message = null
            this.ArrayAll = null
            this.Getcredit()
            this.Success('ส่งข้อความ สำเร็จ')
          })
          .catch(error => {
            this.show = false
            this.SwalError(error.response.data.message)
          })
      } else {
        this.$refs['my-modal'].show()
        this.SwalError('กรุณากรอกข้อมูลให้ครบทุกช่อง')
      }
    },
    countdown() {
      this.remainingCount = this.maxCount - this.message.length
      this.hasError = this.remainingCount < 0
    },
    nameSend() {
      this.$refs['my-modal-name'].show()
    },
    showModal(data) {
      this.senddataone = data
      this.$refs['my-modal'].show()
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
    showModalAll() {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.resdata.data.length; i++) {
        this.ArrayAll.push(this.resdata.data[i].tel)
      }
      this.$refs['my-modal-all'].show()
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
})
</script>

<style scoped>
.active {
  background-color: #9f61b8 !important;
  color: #fff !important;
}
.css_txt_head {
  background-color: #a185b4a1;
  padding: 0.7rem 1rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}
.css_txt_head h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(71, 71, 71);
  margin: 0;
}
.card_credit {
  /* text-align: center; */
  padding: 1rem;
  border-radius: 10px;
  background-color: #480674;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.507);
}
.card_credit p {
  color: #fff;
  font-size: 1.2rem;
}

.card_credit h3 {
  color: #fff;
  font-size: 2.5rem;
  font-weight: 600;
}
.pd-1 {
  padding: 0.95rem 1.4rem !important;
}
.fn {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
    .pd-1 {
    padding: 0.95rem 1.1rem !important;
    }
    .fn {
    font-size: 12px;
    }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.form-item-section {
background-color:$product-details-bg;
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
